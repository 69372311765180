.password-auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-bottom: 100px;
}

.password-auth-form {
  width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
}

.email-div {
  margin-bottom: 20px;
}

.submit-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

a {
  color: blue;
}

.auth-btn {
  background-color: #64a8f4;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s ease;
}

.auth-btn:hover {
  background-color: #3281c2;
}

.error {
  color: red;
}
