@import "variables.css";

nav {
  display: flex;
  justify-content: space-evenly;
  background-color: var(--background-color);
  padding-bottom: 30px;
  padding-top: 20px;
}

nav a,
nav p {
  color: var(--link-color);
  text-decoration: none;
  transition: 0.3s ease;
  cursor: pointer;
  font-family: "Titillium Web", sans-serif;
}

.active {
  color: var(--link-color-active);
}

nav p:hover,
nav a:hover {
  color: var(--link-color-active);
}
