footer {
  background-color: black;
}

footer p {
  margin: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  text-align: center;
  font-size: 12px;
}
