.reset-pass-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  margin-top: 40px;
  margin-bottom: 100px;
}

.reset-pass-form {
  width: 400px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
}

.username-div,
.password-div {
  margin-bottom: 20px;
}

.submit-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

a {
  color: blue;
}

.reset-btn {
  background-color: #f49964;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.3s ease;
}

.reset-btn:hover {
  background-color: #c28432;
}

.error {
  color: red;
}
