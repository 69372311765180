.film {
    display: block;
    margin: 20px 250px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
    text-decoration: none;
    color: black;
    border-radius: 5px;
  }
  
  .film:hover {
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.4);
  }
  